import { useEffect, useState } from 'react';

import { TitleMenu } from './../../Inventary/components/rightMenu/TitleMenu';
import { EconomicNumberForm } from './forms/EconomicNumberForm';
import { LocationsForm } from './forms/LocationsForm';
import { PcModuleForm } from './forms/PcModuleForm';
import { StationsForm } from './forms/StationsForm';
import { TransportCompaniesForm } from './forms/TransportCompaniesForm';
import { OperatorsForm } from './forms/OperatorsForm';
import { PosForm } from './forms/PosForm';
import { DeviceTypesForms } from './forms/DeviceTypesForms';
import { RoutesForm } from './forms/RoutesForm';
import { ProjectForm } from './forms/ProjectForm';
import { ICatalogProvider } from '../../../interfaces/service/CatalogServiceManagerInterface';
import { ProfileCardForm } from './forms/ProfileCardForm';

interface IServiceMenuComponent {
  menuRef?: any;
  dataRow?: any;
  selectMenu?: string;
  setOpenRightMenu: any;
  updateTable: any;
  serviceCatalog: ICatalogProvider;
  //updateTable:  (item:any) => {};
}

export const RightMenu = (props: IServiceMenuComponent) => {
  const { menuRef, dataRow, selectMenu, setOpenRightMenu, updateTable, serviceCatalog } = props;
  const [menu, setMenu] = useState('');

  useEffect(() => {
    setMenu(selectMenu || '');
  });

  /**
   *Choose menu to show
   */
  const renderMenu = (): any => {
    switch (menu) {
      case 'Compañías de transporte':
        return (
          <TransportCompaniesForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );

      case 'Estaciones':
        return (
          <StationsForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );

      case 'Locaciones (locationId)':
        return (
          <LocationsForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Módulos PC':
        return (
          <PcModuleForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Números económicos':
        return (
          <EconomicNumberForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Operadores tecnológicos':
        return (
          <OperatorsForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Puntos de venta':
        return (
          <PosForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Proyectos':
        return (
          <ProjectForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Rutas':
        return (
          <RoutesForm
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
      case 'Tipos de dispositivo':
        return (
          <DeviceTypesForms
            serviceCatalog={serviceCatalog}
            updateTable={updateTable}
            dataRow={dataRow}
            selectMenu={menu}
            setOpenRightMenu={setOpenRightMenu}
          />
        );
        case 'Perfil de tarjetas':
          return (
            <ProfileCardForm
              serviceCatalog={serviceCatalog}
              updateTable={updateTable}
              dataRow={dataRow}
              selectMenu={menu}
              setOpenRightMenu={setOpenRightMenu}
            />
          );
      default:
        return false;
    }
  };

  return (
    <>
      {menu !== 'Tipos de SAM' ? <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0" ref={menuRef}>
        <div className="flex w-full h-[10%] ">
          <TitleMenu name={menu} />
        </div>
        <div className="flex w-full h-[85%] overflow-y-auto">{renderMenu()}</div>
      </div> :
        <>{renderMenu()}</>}
    </>
  );
};
