import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_CARDMAPPING_CATALOG,
  GET_CARDMAPPING_CATALOG_SUCCESS,
  GET_CARDMAPPING_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useCardMappingCatalog = () => {
  const {
    getCardMappingCatalogState,
  } = useContext(StoreContext);
  const { genericApiCallPrepayCards } = useApi();

  const getCardMappingCatalog = async () => {
    getCardMappingCatalogState.dispatch({ type: GET_CARDMAPPING_CATALOG });
    try {
      const result: any = await genericApiCallPrepayCards(
        '/card-mapping',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data;
        getCardMappingCatalogState.dispatch({
          type: GET_CARDMAPPING_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getCardMappingCatalogState.dispatch({
        type: GET_CARDMAPPING_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getCardMappingCatalog
  };
};
