import {
  POST_PROFILE_CARDS_CATALOG,
  POST_PROFILE_CARDS_CATALOG_SUCCESS,
  POST_PROFILE_CARDS_CATALOG_ERROR,
} from '../../../../constants/constantsRed';

export const postProfileCardCatalogRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case POST_PROFILE_CARDS_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case POST_PROFILE_CARDS_CATALOG_SUCCESS:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case POST_PROFILE_CARDS_CATALOG_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};
