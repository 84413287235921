import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  DELETE_PROFILE_CARDS_CATALOG,
  DELETE_PROFILE_CARDS_CATALOG_ERROR,
  DELETE_PROFILE_CARDS_CATALOG_SUCCESS,
  GET_PROFILE_CARDS_CATALOG,
  GET_PROFILE_CARDS_CATALOG_ERROR,
  GET_PROFILE_CARDS_CATALOG_SUCCESS,
  POST_PROFILE_CARDS_CATALOG,
  POST_PROFILE_CARDS_CATALOG_ERROR,
  POST_PROFILE_CARDS_CATALOG_SUCCESS,
  PUT_PROFILE_CARDS_CATALOG,
  PUT_PROFILE_CARDS_CATALOG_ERROR,
  PUT_PROFILE_CARDS_CATALOG_SUCCESS,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useProfileCardsCatalogs = () => {
  const {
    getProfileCardCatalogState,
    postProfileCardCatalogState,
    putProfileCardCatalogState,
    deleteProfileCardCatalogState,
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getProfileCardsCatalog = async () => {
    getProfileCardCatalogState.dispatch({ type: GET_PROFILE_CARDS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/profile-card',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data;
        getProfileCardCatalogState.dispatch({
          type: GET_PROFILE_CARDS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getProfileCardCatalogState.dispatch({
        type: GET_PROFILE_CARDS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postProfileCardsCatalog = async (data: any) => {
    postProfileCardCatalogState.dispatch({ type: POST_PROFILE_CARDS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/profile-card',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent(
          'success',
          '',
          'Se ha añadido el registro correctamente'
        );
        const payload = result.data.payload;
        postProfileCardCatalogState.dispatch({
          type: POST_PROFILE_CARDS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postProfileCardCatalogState.dispatch({
        type: POST_PROFILE_CARDS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putProfileCardsCatalog = async (data: any) => {
    putProfileCardCatalogState.dispatch({ type: PUT_PROFILE_CARDS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/profile-card',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent(
          'success',
          '',
          'Se ha editado el registro correctamente'
        );
        const payload = result.data.payload;
        putProfileCardCatalogState.dispatch({
          type: PUT_PROFILE_CARDS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putProfileCardCatalogState.dispatch({
        type: PUT_PROFILE_CARDS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteProfileCardsCatalog = async (id: string) => {
    deleteProfileCardCatalogState.dispatch({
      type: DELETE_PROFILE_CARDS_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/profile-card/'+id,
        'DELETE',
        {},
        {},
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteProfileCardCatalogState.dispatch({
          type: DELETE_PROFILE_CARDS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteProfileCardCatalogState.dispatch({
        type: DELETE_PROFILE_CARDS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getProfileCardsCatalog,
    postProfileCardsCatalog,
    putProfileCardsCatalog,
    deleteProfileCardsCatalog,
  };
};
