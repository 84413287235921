import { createContext, Dispatch, useReducer, useState } from 'react';

import { deleteDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/deleteDeviceTypesCatalogRed';
import { getDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/getDeviceTypesCatalogRed';
import { postDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/postDeviceTypesCatalogRed';
import { putDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/putDeviceTypesCatalogRed';
import { getEconomicNumbersCatalogRed } from '../business/Reducers/catalogs/economicNumbers/getEconomicNumbersCatalogRed';
import { getExportCatalogRed } from '../business/Reducers/catalogs/getExportCatalogRed';
import { getPcModulesCatalogRed } from '../business/Reducers/catalogs/getPcModulesCatalogRed';
import { getProjectsCatalogRed } from '../business/Reducers/catalogs/projects/getProjectsCatalogRed';
import { postProjectsCatalogRed } from '../business/Reducers/catalogs/projects/postProjectsCatalogRed';
import { putProjectsCatalogRed } from '../business/Reducers/catalogs/projects/putProjectsCatalogRed';
import { deleteProjectsCatalogRed } from '../business/Reducers/catalogs/projects/deleteProjectsCatalogRed';
import { getLocationsCatalogRed } from '../business/Reducers/catalogs/locations/getLocationsCatalogRed';
import { getOperatorsCatalogRed } from '../business/Reducers/catalogs/operators/getOperatorsCatalogRed';
import { deletePosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/deletePointsOfSalesCatalogRed';
import { getPointsOfSalesCatalogRed } from '../business/Reducers/catalogs/points_of_sales/getPointsOfSalesCatalogRed';
import { postPosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/postPointsOfSalesCatalogRed';
import { putPosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/putPointsOfSalesCatalogRed';
import { deleteRoutesCatalogRed } from '../business/Reducers/catalogs/routes/deleteRoutesCatalogRed';
import { getRoutesCatalogRed } from '../business/Reducers/catalogs/routes/getRoutesCatalogRed';
import { postRoutesCatalogRed } from '../business/Reducers/catalogs/routes/postRoutesCatalogRed';
import { putRoutesCatalogRed } from '../business/Reducers/catalogs/routes/putRoutesCatalogRed';
import { deleteStationsCatalogRed } from '../business/Reducers/catalogs/stations/deleteStationsCatalogRed';
import { getStationsCatalogRed } from '../business/Reducers/catalogs/stations/getStationsCatalogRed';
import { postStationsCatalogRed } from '../business/Reducers/catalogs/stations/postStationsCatalogRed';
import { putStationsCatalogRed } from '../business/Reducers/catalogs/stations/putStationsCatalogRed';
import { deleteTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/deleteTransportCompaniesCatalogRed';
import { getTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/getTransportCompaniesCatalogRed';
import { postTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/postTransportCompaniesCatalogRed';
import { putTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/putTransportCompaniesCatalogRed';
import { loadingRed } from '../business/Reducers/loading/loadingRed';
import { patchSamRed } from '../business/Reducers/sam/patchSamRed';
import { putSamRed } from '../business/Reducers/sam/putSamRed';
import { getSettingsRed } from '../business/Reducers/settings/getSettingsRed';
import { globalInitialState } from '../business/States/initialStateGlobal';
import { deleteEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/deleteEconomicNumbersCatalogRed';
import { postEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/postEconomicNumbersCatalogRed';
import { putEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/putEconomicNumbersCatalogRed';
import { deleteLocationsCatalogRed } from './../business/Reducers/catalogs/locations/deleteLocationsCatalogRed';
import { postLocationsCatalogRed } from './../business/Reducers/catalogs/locations/postLocationsCatalogRed';
import { putLocationsCatalogRed } from './../business/Reducers/catalogs/locations/putLocationsCatalogRed';
import { deleteOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/deleteOperatorsCatalogRed';
import { postOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/postOperatorsCatalogRed';
import { putOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/putOperatorsCatalogRed';
import { postExportDeviceTypesCatalogRed } from './../business/Reducers/catalogs/postExportDeviceTypesCatalogRed';
import { getSamByIdRed } from './../business/Reducers/sam/getSamByIdRed';
import { getSamTypesRed } from './../business/Reducers/sam/getSamTypesRed';
import { deleteUserManagerRed } from './../business/Reducers/userManager/deleteUserManagerRed';
import { getRolesRed } from './../business/Reducers/userManager/getRolesRed';
import { patchRoleRed } from './../business/Reducers/userManager/patchRoleRed';
import { postRoleRed } from './../business/Reducers/userManager/postRoleRed';
import { deleteRoleRed } from './../business/Reducers/userManager/deleteRolesRed';
import { getUserManagerRed } from './../business/Reducers/userManager/getUserManagerRed';
import { patchUserManagerRed } from './../business/Reducers/userManager/patchRolesRed';
import { postUserManagerRed } from './../business/Reducers/userManager/postUserManagerRed';
import { useGetConfigurationTriggerState, useGetInventoryState, useGetInvetoryToExcelState, useGetSamById, usePatchEditSamState, usePostConfigurationTriggerState, usePutEditSamState, usePutRechargeCountersState } from './../business/States/inventory/inventoryStates';
import { useCountersFromSamTypeState, useCountersFromSamIdState } from './../business/States/countersFromSamType/countersFromSamTypeState';
import { 
  useDeleteDeviceTypesCatalogState, 
  useDeleteEconomicNumbersCatalogState, 
  useDeleteLocationCatalogState, 
  useDeleteOperatorsCatalogState, 
  useDeletePosCatalogState, 
  useDeleteProfileCardCatalogState, 
  useDeleteProjectsCatalogState, 
  useDeleteRoutesCatalogState, 
  useDeleteStationsCatalogState, 
  useDeleteTransportCompaniesCatalogState, 
  useGetCardMappingCatalogState, 
  useGetCatalogDevices, 
  useGetCatalogOperators, 
  useGetCatalogProjects, 
  useGetDeviceTypesCatalogState, 
  useGetEconomicNumbersCatalogState, 
  useGetJobPositionCatalogState, 
  useGetLocationCatalogState, 
  useGetOperatorsCatalogState, 
  useGetPointsOfSalesCatalogState, 
  useGetProfileCardCatalogState, 
  useGetProjectsCatalogState, 
  useGetRoutesCatalogState, 
  useGetSamCounter, 
  useGetSamKeyVersions,
  useGetStationsCatalogState, 
  useGetTransportCompaniesCatalogState, 
  usePatchSamCounter, 
  usePostDeviceTypesCatalogState, 
  usePostEconomicNumbersCatalogState, 
  usePostExportDeviceTypesCatalogState, 
  usePostLocationCatalogState, 
  usePostOperatorsCatalogState, 
  usePostPosCatalogState, 
  usePostProfileCardCatalogState, 
  usePostProjectsCatalogState, 
  usePostRoutesCatalogState, 
  usePostStationsCatalogState, 
  usePostTransportCompaniesCatalogState, 
  usePutDeviceTypesCatalogState, 
  usePutEconomicNumbersCatalogState, 
  usePutLocationCatalogState, 
  usePutOperatorsCatalogState, 
  usePutPosCatalogState, 
  usePutProfileCardCatalogState, 
  usePutProjectsCatalogState, 
  usePutRoutesCatalogState, 
  usePutStationsCatalogState, 
  usePutTransportCompaniesCatalogState 
} from './../business/States/catalog/catalogStates';
import { useUserHistoryState } from './../business/States/userHistory/userHistory';
import { useSamTypes } from './../business/States/inventory/inventoryStates';
import { useHistoryCardState } from './../business/States/historyCard/historyCardStates';


interface IProviderState {
  data: any;
  loading: boolean;
  error: any;
}

interface IProvider {
  loadingState: any;
  dispatchLoading: (data: any) => void;

  openRightMenu: boolean;
  setOpenRightMenu: (any: boolean) => void;

  getSettingsState: IProviderState;
  dispatchSettings: (data: any) => void;

  /**
   * Sam Zone
   */

  patchEditSamState: { state: any; dispatch: Dispatch<any>; }
  // dispatchPatchEditSam: (data: any) => void;

  putEditSamState: { state: any; dispatch: Dispatch<any>; }
  // dispatchPutEditSam: (data: any) => void;

  getSamByIdState: { state: any; dispatch: Dispatch<any>; }
  // dispatchGetSamById: (data: any) => void;

  getSamTypesState: IProviderState;
  dispatchGetSamTypes: (data: any) => void;

  putRechargeCounterState: { state: any; dispatch: Dispatch<any>; }

  /**
   * Users Zone
   */

  getUserManagerState: IProviderState;
  dispatchGetUserManager: (data: any) => void;

  postUserManagerState: IProviderState;
  dispatchPostUserManager: (data: any) => void;

  deleteUserManagerState: IProviderState;
  dispatchDeleteUserManager: (data: any) => void;

  patchUserManagerState: IProviderState;
  dispatchPatchUserManager: (data: any) => void;

  //Roles

  getRolesState: IProviderState;
  dispatchGetRoles: (data: any) => void;

  postRoleState: IProviderState;
  dispatchPostRole: (data: any) => void;

  patchRoleState: IProviderState;
  dispatchPatchRole: (data: any) => void;

  deleteRoleState: IProviderState;
  dispatchDeleteRole: (data: any) => void;

  /**
   * Catalogs Zone
   */

  // Operators
  getCatalogOperatorsState: { state: any; dispatch: Dispatch<any>; }

  getOperatorsCatalogState: { state: any; dispatch: Dispatch<any>; }

  postOperatorsCatalogState: { state: any; dispatch: Dispatch<any>; }

  putOperatorsCatalogState: { state: any; dispatch: Dispatch<any>; }

  deleteOperatorsCatalogState: { state: any; dispatch: Dispatch<any>; }

  //locations

  getLocationCatalogState: { state: any; dispatch: Dispatch<any>; }

  postLocationsCatalogState: { state: any; dispatch: Dispatch<any>; }

  putLocationsCatalogState: { state: any; dispatch: Dispatch<any>; }

  deleteLocationsCatalogState: { state: any; dispatch: Dispatch<any>; }

  //Device types

  getCatalogDeviceState: { state: any; dispatch: Dispatch<any>; };

  getDeviceTypesCatalogState: { state: any; dispatch: Dispatch<any>; };

  postExportDeviceTypesCatalogState: { state: any; dispatch: Dispatch<any>; };

  postDeviceTypesCatalogState: { state: any; dispatch: Dispatch<any>; };

  putDeviceTypesCatalogState: { state: any; dispatch: Dispatch<any>; };

  deleteDeviceTypesCatalogState: { state: any; dispatch: Dispatch<any>; };

  // Economic numbers

  getEconomicNumbersCatalogState: { state: any; dispatch: Dispatch<any>; };

  postEconomicNumbersCatalogState: { state: any; dispatch: Dispatch<any>; };

  putEconomicNumbersCatalogState: { state: any; dispatch: Dispatch<any>; };

  deleteEconomicNumbersCatalogState: { state: any; dispatch: Dispatch<any>; };

  getPcModulesCatalogState: IProviderState;
  dispatchGetPcModulesCatalog: (data: any) => void;

  // Job position

  getjobPositionCatalogState: { state: any; dispatch: Dispatch<any>; };

  // Job position

  getCardMappingCatalogState: { state: any; dispatch: Dispatch<any>; };

  // Point of sales

  getPointsOfSalesCatalogState: { state: any; dispatch: Dispatch<any>; };

  postPosCatalogState: { state: any; dispatch: Dispatch<any>; };

  putPosCatalogState: { state: any; dispatch: Dispatch<any>; };

  deletePosCatalogState: { state: any; dispatch: Dispatch<any>; };

  //Projects

  getProfileCardCatalogState: { state: any; dispatch: Dispatch<any>; }

  postProfileCardCatalogState: { state: any; dispatch: Dispatch<any>; }

  putProfileCardCatalogState: { state: any; dispatch: Dispatch<any>; }

  deleteProfileCardCatalogState: { state: any; dispatch: Dispatch<any>; }

  //Projects

  getCatalogProjectsState: { state: any; dispatch: Dispatch<any>; }

  getProjectsCatalogState: { state: any; dispatch: Dispatch<any>; }

  postProjectsCatalogState: { state: any; dispatch: Dispatch<any>; }

  putProjectsCatalogState: { state: any; dispatch: Dispatch<any>; }

  deleteProjectsCatalogState: { state: any; dispatch: Dispatch<any>; }

  //Routes

  getRoutesCatalogState: { state: any; dispatch: Dispatch<any>; }

  postRoutesCatalogState: { state: any; dispatch: Dispatch<any>; }

  putRoutesCatalogState: { state: any; dispatch: Dispatch<any>; }

  deleteRoutesCatalogState: { state: any; dispatch: Dispatch<any>; }

  //Stations

  getStationsCatalogState: { state: any; dispatch: Dispatch<any>; }

  postStationsCatalogState: { state: any; dispatch: Dispatch<any>; }

  putStationsCatalogState: { state: any; dispatch: Dispatch<any>; }


  deleteStationsCatalogState: { state: any; dispatch: Dispatch<any>; }


  //Transport companies

  getTransportCompaniesCatalogState: { state: any; dispatch: Dispatch<any>; }


  postTransportCompaniesCatalogState: { state: any; dispatch: Dispatch<any>; }


  putTransportCompaniesCatalogState: { state: any; dispatch: Dispatch<any>; }


  deleteTransportCompaniesCatalogState: { state: any; dispatch: Dispatch<any>; }

  //Configuration Trigger

  getConfigurationTriggerState: { state: any; dispatch: Dispatch<any>; }


  postConfigurationTriggerState: { state: any; dispatch: Dispatch<any>; }


  getExportCatalogState: IProviderState;
  dispatchGetExportCatalog: (data: any) => void;

  inventoryStateContext: { state: any; dispatch: Dispatch<any>; }

  userHistoryStateContext: { state: any; dispatch: Dispatch<any>; }

  inventoryToExcelStateContext: { state: any; dispatch: Dispatch<any>; }

  countersFromSamTypeStateContext: { state: any; dispatch: Dispatch<any>; }

  countersFromSamIdStateContext: { state: any; dispatch: Dispatch<any>; }

  historyCardStateContext: { state: any; dispatch: Dispatch<any>; }

  catalogSamCounter: { state: any; dispatch: Dispatch<any>; }

  patchSamCounter: { state: any; dispatch: Dispatch<any>; }

  getSamTypesStates: { state: any; dispatch: Dispatch<any>; }

  getSamKeyVersions: { state: any; dispatch: Dispatch<any>; }

}

export const StoreContext = createContext<IProvider>({} as IProvider);

export const StoreProvider = (props: any) => {
  const [loadingState, dispatchLoading] = useReducer(loadingRed, false);
  const [openRightMenu, setOpenRightMenu] = useState(false);

  const [getSettingsState, dispatchSettings] = useReducer(
    getSettingsRed,
    globalInitialState
  );

  // Sam Zone

  const patchEditSamState = usePatchEditSamState()

  const putEditSamState = usePutEditSamState();

  const getSamByIdState = useGetSamById();

  const [getSamTypesState, dispatchGetSamTypes] = useReducer(
    getSamTypesRed,
    globalInitialState
  );

  const putRechargeCounterState = usePutRechargeCountersState();
  //--End Sam Zone

  // Users zone

  const [getUserManagerState, dispatchGetUserManager] = useReducer(
    getUserManagerRed,
    globalInitialState
  );

  const [deleteUserManagerState, dispatchDeleteUserManager] = useReducer(
    deleteUserManagerRed,
    globalInitialState
  );

  const [patchUserManagerState, dispatchPatchUserManager] = useReducer(
    patchUserManagerRed,
    globalInitialState
  );

  const [postUserManagerState, dispatchPostUserManager] = useReducer(
    postUserManagerRed,
    globalInitialState
  );

  const [getRolesState, dispatchGetRoles] = useReducer(
    getRolesRed,
    globalInitialState
  );

  const [postRoleState, dispatchPostRole] = useReducer(
    postRoleRed,
    globalInitialState
  );

  const [patchRoleState, dispatchPatchRole] = useReducer(
    patchRoleRed,
    globalInitialState
  );

  const [deleteRoleState, dispatchDeleteRole] = useReducer(
    deleteRoleRed,
    globalInitialState
  );

  //--End Users zone

  // Catalogs zone

  // Operators
  const getCatalogOperatorsState = useGetCatalogOperators();

  const getOperatorsCatalogState = useGetOperatorsCatalogState();

  const postOperatorsCatalogState = usePostOperatorsCatalogState();

  const putOperatorsCatalogState = usePutOperatorsCatalogState();

  const deleteOperatorsCatalogState = useDeleteOperatorsCatalogState();

  //Locations

  const getLocationCatalogState = useGetLocationCatalogState();

  const postLocationsCatalogState = usePostLocationCatalogState();

  const putLocationsCatalogState = usePutLocationCatalogState();

  const deleteLocationsCatalogState = useDeleteLocationCatalogState();

  // Device types

  const getCatalogDeviceState = useGetCatalogDevices();

  const getDeviceTypesCatalogState = useGetDeviceTypesCatalogState();

  const postDeviceTypesCatalogState = usePostDeviceTypesCatalogState();

  const putDeviceTypesCatalogState = usePutDeviceTypesCatalogState();

  const deleteDeviceTypesCatalogState = useDeleteDeviceTypesCatalogState();

  const postExportDeviceTypesCatalogState = usePostExportDeviceTypesCatalogState();

  // Economic numbers

  const getEconomicNumbersCatalogState = useGetEconomicNumbersCatalogState();

  const postEconomicNumbersCatalogState = usePostEconomicNumbersCatalogState();

  const putEconomicNumbersCatalogState = usePutEconomicNumbersCatalogState();

  const deleteEconomicNumbersCatalogState = useDeleteEconomicNumbersCatalogState();

  const [getPcModulesCatalogState, dispatchGetPcModulesCatalog] = useReducer(
    getPcModulesCatalogRed,
    globalInitialState
  );

  //job position

  const getjobPositionCatalogState = useGetJobPositionCatalogState()

  //car dmapping

  const getCardMappingCatalogState = useGetCardMappingCatalogState()

  // Pos

  const getPointsOfSalesCatalogState = useGetPointsOfSalesCatalogState();

  const postPosCatalogState = usePostPosCatalogState();

  const putPosCatalogState = usePutPosCatalogState();

  const deletePosCatalogState = useDeletePosCatalogState();

    // Profile card

    const getProfileCardCatalogState = useGetProfileCardCatalogState();
  
    const postProfileCardCatalogState = usePostProfileCardCatalogState();
  
    const putProfileCardCatalogState = usePutProfileCardCatalogState();
  
    const deleteProfileCardCatalogState = useDeleteProfileCardCatalogState();

  //Projects

  const getCatalogProjectsState = useGetCatalogProjects();

  const getProjectsCatalogState = useGetProjectsCatalogState();

  const postProjectsCatalogState = usePostProjectsCatalogState();

  const putProjectsCatalogState = usePutProjectsCatalogState();

  const deleteProjectsCatalogState = useDeleteProjectsCatalogState();

  // Routes

  const getRoutesCatalogState = useGetRoutesCatalogState();

  const postRoutesCatalogState = usePostRoutesCatalogState();

  const putRoutesCatalogState = usePutRoutesCatalogState();

  const deleteRoutesCatalogState = useDeleteRoutesCatalogState();

  // Stations

  const getStationsCatalogState = useGetStationsCatalogState();

  const postStationsCatalogState = usePostStationsCatalogState();

  const putStationsCatalogState = usePutStationsCatalogState();

  const deleteStationsCatalogState = useDeleteStationsCatalogState();

  // Transports

  const getTransportCompaniesCatalogState = useGetTransportCompaniesCatalogState()

  const postTransportCompaniesCatalogState = usePostTransportCompaniesCatalogState()

  const putTransportCompaniesCatalogState = usePutTransportCompaniesCatalogState()

  const deleteTransportCompaniesCatalogState = useDeleteTransportCompaniesCatalogState()

  //Configuration Trigger

  const getConfigurationTriggerState = useGetConfigurationTriggerState();

  const postConfigurationTriggerState = usePostConfigurationTriggerState();

  const [getExportCatalogState, dispatchGetExportCatalog] = useReducer(
    getExportCatalogRed,
    globalInitialState
  );

  const inventoryStateContext = useGetInventoryState();

  const inventoryToExcelStateContext = useGetInvetoryToExcelState();
  const countersFromSamTypeStateContext = useCountersFromSamTypeState();
  const countersFromSamIdStateContext = useCountersFromSamIdState();


  const catalogSamCounter = useGetSamCounter();

  const patchSamCounter = usePatchSamCounter();

  const userHistoryStateContext = useUserHistoryState();

  const historyCardStateContext = useHistoryCardState();

  const getSamTypesStates = useSamTypes();

  const getSamKeyVersions = useGetSamKeyVersions();

  return (
    <StoreContext.Provider
      value={{
        inventoryStateContext,

        inventoryToExcelStateContext,
        countersFromSamTypeStateContext,
        countersFromSamIdStateContext,
        openRightMenu,
        setOpenRightMenu,

        loadingState,
        dispatchLoading,

        getSettingsState,
        dispatchSettings,

        /**
         * User Zone
         */

        getUserManagerState,
        dispatchGetUserManager,

        postUserManagerState,
        dispatchPostUserManager,

        patchUserManagerState,
        dispatchPatchUserManager,

        deleteUserManagerState,
        dispatchDeleteUserManager,

        getRolesState,
        dispatchGetRoles,

        postRoleState,
        dispatchPostRole,

        patchRoleState,
        dispatchPatchRole,

        deleteRoleState,
        dispatchDeleteRole,

        /**
         * Sam Zone
         */

        patchEditSamState,

        putEditSamState,

        getSamByIdState,

        getSamTypesState,
        dispatchGetSamTypes,

        putRechargeCounterState,

        /**
         * Catalogs Zone
         */

        // Operators 1st Filters, 2nd 3rd ... for catalog
        getCatalogOperatorsState,

        getOperatorsCatalogState,

        postOperatorsCatalogState,

        putOperatorsCatalogState,

        deleteOperatorsCatalogState,

        // Locations

        getLocationCatalogState,

        postLocationsCatalogState,

        putLocationsCatalogState,

        deleteLocationsCatalogState,

        // Device types

        getCatalogDeviceState,

        getDeviceTypesCatalogState,

        postExportDeviceTypesCatalogState,

        postDeviceTypesCatalogState,

        putDeviceTypesCatalogState,

        deleteDeviceTypesCatalogState,

        // Economic numbers

        getEconomicNumbersCatalogState,

        postEconomicNumbersCatalogState,

        putEconomicNumbersCatalogState,

        deleteEconomicNumbersCatalogState,

        getPcModulesCatalogState,
        dispatchGetPcModulesCatalog,

        // job position 

        getjobPositionCatalogState,

        // card mapping

        getCardMappingCatalogState,

        // Pos

        getPointsOfSalesCatalogState,

        postPosCatalogState,

        putPosCatalogState,

        deletePosCatalogState,

        // Catalog Projects

        getProfileCardCatalogState,

        postProfileCardCatalogState,

        putProfileCardCatalogState,

        deleteProfileCardCatalogState,

        //Projects

        getCatalogProjectsState,

        getProjectsCatalogState,

        postProjectsCatalogState,

        putProjectsCatalogState,

        deleteProjectsCatalogState,

        // Routes

        getRoutesCatalogState,

        postRoutesCatalogState,

        putRoutesCatalogState,

        deleteRoutesCatalogState,

        //Stations

        getStationsCatalogState,

        postStationsCatalogState,

        putStationsCatalogState,

        deleteStationsCatalogState,

        // Transport companies

        getTransportCompaniesCatalogState,

        postTransportCompaniesCatalogState,

        putTransportCompaniesCatalogState,

        deleteTransportCompaniesCatalogState,

        //Configuration Trigger

        getConfigurationTriggerState,

        postConfigurationTriggerState,

        getExportCatalogState,
        dispatchGetExportCatalog,

        catalogSamCounter,

        patchSamCounter,

        userHistoryStateContext,
        historyCardStateContext,
        getSamTypesStates,

        getSamKeyVersions
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};
