import { InputNumber } from 'primereact/inputnumber';

type TextInputProps = {
  label?: string | null;
  name: string;
  value?: number;
  className?: string;
  placeholder?: string;
  onChange?: any;
  classSpan?: string;
  useGrouping?: boolean;
};

export const InputNumberComponent = (props: TextInputProps) => {
  const {
    label,
    name,
    value,
    className,
    placeholder,
    onChange,
    useGrouping,
  } = props;
  const classCss = className || 'p-inputtext-sm';
  return (
    <div className="flex w-full flex-col">
      <>
        {' '}
        {label !== undefined ? (
          <p className="font-medium text-md mb-2 mt-3">{label}</p>
        ) : (
          <></>
        )}{' '}
      </>

      <InputNumber
        inputId={'in_year'}
        name={name}
        value={value}
        placeholder={placeholder}
        className={classCss}
        onValueChange={onChange}
        useGrouping={useGrouping}
      />
    </div>
  );
};
