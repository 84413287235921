import { FunctionComponent, useContext } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { CatalogsList } from './components/CatalogsList';
import { TableCatalogComponent } from './components/TableCatalogComponent';

import { RightMenu } from './components/RightMenu';
import { ICatalogProvider } from '../../interfaces/service/CatalogServiceManagerInterface';
import { CatalogContextSM } from '../../serviceContextManager/catalog/CatalogServiceManager';
import { DeleteItemModal } from './components/DeleteItemModal';
import { ConfirmCloseRightMenu } from './components/ConfirmCloseRightMenu';
import { DisplayMappingJsonDataModal } from './components/DisplayMappingSamModal';

export const Catalogs: FunctionComponent = () => {
  const serviceCatalog = useContext<ICatalogProvider>(CatalogContextSM);

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row">
        <div className="flex h-full w-[14%] border-solid border-r-2 border-grey relative">
          <CatalogsList
            serviceCatalog={serviceCatalog}
          />
        </div>

        <div className="flex grow h-full w-[86%] relative flex-col content-center items-center justify-center p-10 divide-solid">
          <TableCatalogComponent
            serviceCatalog={serviceCatalog}
          />
          <DeleteItemModal
            serviceCatalog={serviceCatalog}
          />
          <DisplayMappingJsonDataModal
            serviceCatalog={serviceCatalog}
          />
          <ConfirmCloseRightMenu
            serviceCatalog={serviceCatalog}
          />
        </div>
      </div>
    );
  };


  const renderRightComponent = () => {
    return (
      <RightMenu
        menuRef={serviceCatalog.menuRef}
        dataRow={serviceCatalog.dataRow}
        selectMenu={serviceCatalog.selectMenu}
        setOpenRightMenu={serviceCatalog.setOpenRightMenu}
        updateTable={serviceCatalog.updateCatalogTable}
        serviceCatalog={serviceCatalog}
      />
    );
  };

  return (
    <MainContainer
      loading={serviceCatalog.loading}
      openRightMenu={serviceCatalog.openRightMenu}
      setOpenRightMenu={serviceCatalog.setOpenRightMenu}
      rightMenuContent={renderRightComponent}>
      {renderContent()}
    </MainContainer>
  );
};
