import { useState } from 'react';
import {
  RiBroadcastLine,
  RiCalculatorLine,
  RiFileExcel2Line,
} from 'react-icons/ri';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { CalendarComponent } from '../../../components/CalendarComponent';
import { InputComponent } from '../../../components/InputComponent';
import { MultiSelectComponent } from '../../../components/MultiSelectComponent';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';
import { validatePermissions } from '../../../utils/utils';
import { PermitsList } from './../../../permitsList';

interface IFiltersComponent {
  serviceInvetory: IInventoryProvider;
}

export const Filters = (props: IFiltersComponent) => {
  const { serviceInvetory } = props;

  const [fromDateInput, setFromDateInput] = useState<any>();
  const [toDateInput, setToDateInput] = useState<any>();

  const dualTextTemplate = (option: any) => {
    return (
      <div>
        <span className="text-grey">({option.code}) </span>
        <span>{option.name}</span>
      </div>
    );
  };

  const renderSelectDevices = () => {
    return (
      <div className="w-[15%] mx-1">
        <MultiSelectComponent
          options={serviceInvetory.deviceList}
          optionLabel={'name'}
          placeholder="Dispositivos"
          value={serviceInvetory.devicesSelected}
          className="h-12"
          onChange={serviceInvetory.selectDevice}
          max={0}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderSelectOperators = () => {
    return (
      <div className="w-[15%] mx-1">
        <MultiSelectComponent
          options={serviceInvetory.operatorsList}
          optionLabel={'name'}
          placeholder="Operadores tecnológicos"
          value={serviceInvetory.operatorsSelected}
          className="h-12"
          onChange={serviceInvetory.selectOperators}
          max={2}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderSelectProjects = () => {
    return (
      <div className="w-[15%] mx-1">
        <MultiSelectComponent
          options={serviceInvetory.projectsList}
          optionLabel={'name'}
          placeholder="Proyectos"
          value={serviceInvetory.projectsSelected}
          className="h-12"
          onChange={serviceInvetory.selectProjects}
          max={2}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderSelectSamTypes = () => {
    return (
      <div className="w-[15%] mx-1">
        <MultiSelectComponent
          options={serviceInvetory.samTypeList}
          optionLabel={'name'}
          placeholder="Tipos de SAM"
          value={serviceInvetory.samTypeSelected}
          className="h-12"
          onChange={serviceInvetory.selectSamType}
          max={2}
          itemTemplate={dualTextTemplate}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderFindSearch = () => {
    return (
      <div className="w-[15%] mx-1">
        <InputComponent
          type={'text'}
          name={'find'}
          className={'h-12'}
          value={serviceInvetory.samIdField}
          onChange={serviceInvetory.handleFindSearch}
          placeholder="Filtrar por SAM ID"
          classSpan={'p-input-icon-right'}
        />
      </div>
    );
  };

  const renderFindSearchDeviceId = () => {
    return (
      <div className="w-[15%] mx-1">
        <InputComponent
          type={'text'}
          name={'find'}
          className={'h-12'}
          value={serviceInvetory.deviceIdField}
          onChange={serviceInvetory.handeFindSearchDeviceId}
          placeholder="Filtrar por Id de equipo"
          classSpan={'p-input-icon-right'}
        />
      </div>
    );
  };

  const renderSelectColumn = () => {
    return (
      <div className="w-[60%] mx-1">
        <MultiSelectComponent
          options={serviceInvetory.allColumnsFilter}
          optionLabel={'header'}
          placeholder="Seleccionar columnas"
          value={serviceInvetory.columnsSelected}
          className=" h-12"
          onChange={serviceInvetory.handleSelectColumn}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderExcel = () => {
    return validatePermissions(PermitsList.SAM_INVENTORY_EXPORT) ? (
      <div className="flex h-12 w-[15%] text-[50px] text-alternative_green content-center items-center cursor-pointer">
        <RiFileExcel2Line
          onClick={serviceInvetory.generateExcel}
          title="Exportar a Excel"
        />
      </div>
    ) : (
      <></>
    );
  };

  const renderCountersRecharge = () => {
    let firstSamTypeId: any = undefined;
    if (
      serviceInvetory.selectedSam !== undefined &&
      serviceInvetory.selectedSam.length > 0
    ) {
      firstSamTypeId = serviceInvetory.selectedSam[0].SamTypeId;
    }
    const allSameSamType = serviceInvetory.selectedSam?.every(
      (item: any) => item.SamTypeId === firstSamTypeId
    );
    return (
      <div
        className={`flex h-12 text-[50px] w-[15%] ${
          serviceInvetory.selectedSam !== undefined &&
          serviceInvetory.selectedSam.length > 0 &&
          allSameSamType
            ? 'text-alternative_green cursor-pointer'
            : 'text-grey cursor-default'
        }`}>
        <RiCalculatorLine
          onClick={() =>
            serviceInvetory.selectedSam &&
            serviceInvetory.selectedSam.length > 0 &&
            serviceInvetory.openRechargesCounterModal()
          }
          title="Agregar contadores"
        />
      </div>
    );
  };

  const renderLimitTriggersComunication = () => {
    return (
      <div
        className={`flex h-12 text-[50px] w-[15%] ${
          serviceInvetory.selectedSam !== undefined &&
          serviceInvetory.selectedSam.length > 0
            ? 'text-alternative_green cursor-pointer'
            : 'text-grey cursor-default'
        }`}>
        <RiBroadcastLine
          title={'Captura de limites comunicación'}
          onClick={() =>
            serviceInvetory.selectedSam &&
            serviceInvetory.selectedSam.length > 0 &&
            serviceInvetory.openTriggerComunicationModal()
          }
        />
      </div>
    );
  };

  const renderButtonSendData = () => {
    return (
      <div className="w-[10%] h-12">
        <ButtonComponent
          label="Enviar"
          onClick={serviceInvetory.sendData}
          className={'h-full'}
        />
      </div>
    );
  };

  const renderFromDate = () => {
    return (
      <div className="w-[15%] mx-1">
        <CalendarComponent
          name={'fromDate'}
          onChange={(e: any) => {
            setFromDateInput(e.value);
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value);
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              serviceInvetory.setFromDateField(formattedDate);
            } else {
              serviceInvetory.setFromDateField('');
            }
          }}
          value={fromDateInput}
          placeholder="Fecha inicio"
          className=" h-12"
        />
      </div>
    );
  };

  const renderToDate = () => {
    return (
      <div className="w-[15%] mx-1">
        <CalendarComponent
          name={'toDate'}
          onChange={(e: any) => {
            setToDateInput(e.value);
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value);
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              serviceInvetory.setToDateField(formattedDate);
            } else {
              serviceInvetory.setToDateField('');
            }
          }}
          value={toDateInput}
          placeholder="Fecha inicio"
          className=" h-12"
        />
      </div>
    );
  };

  return (
    <div>
      <div className="w-full h-16 flex pr-2">
        <div className="flex w-[80%] items-center"></div>
        <div className="flex w-[20%] items-center justify-end">
          {renderLimitTriggersComunication()}
          {renderCountersRecharge()}
          {renderExcel()}
          {renderSelectColumn()}
        </div>
      </div>
      <div className="w-full h-16 flex pl-2">
        <div className="flex w-[100%] items-center">
          {renderFromDate()}
          {renderToDate()}
          {renderSelectDevices()}
          {renderSelectOperators()}
          {renderSelectProjects()}
          {renderSelectSamTypes()}
          {renderFindSearch()}
          {renderFindSearchDeviceId()}
          {renderButtonSendData()}
        </div>
      </div>
    </div>
  );
};
