import { MultiSelectChangeEvent } from 'primereact/multiselect';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { InputComponent } from '../../../components/InputComponent';
import { CalendarComponent } from '../../../components/CalendarComponent';
import { MultiSelectComponent } from '../../../components/MultiSelectComponent';
import { RiBarChartBoxFill,RiTableLine } from 'react-icons/ri';
import { IHistoryCardProvider } from '../../../interfaces/service/HistoryCardServiceManagerInterface';
import { DropdownComponent } from '../../../components/DropdownComponen';
import { DropdownChangeEvent } from 'primereact/dropdown';


export const Filters = (props: IHistoryCardProvider) => {

  const renderSelectDevices = () => {
    return (
      <div className="w-3/12 mt-auto">
        <MultiSelectComponent
          options={props.devices}
          optionLabel={'name'}
          label='Dispositivos:'
          placeholder="Dispositivos"
          value={props.devicesSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => props.selectDevices(e)}
          max={1}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectProject = () => {
    return (
      <div className="w-3/12 mt-auto">
        <MultiSelectComponent
          options={props.projects}
          optionLabel={'name'}
          label='Proyectos:'
          placeholder="Proyectos"
          value={props.projectsSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => props.selectProject(e)}
          max={1}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectTimePeriod = () => {
    return (
      <div className="!w-3/12 mt-auto">
        <DropdownComponent
          options={props.timePeriod}
          label='Tipo de periodo: '
          optionLabel={'name'}
          placeholder="Tipo de periodo"
          value={props.timePeriodSelected}
          className=" h-12 !w-full"
          onChange={(e: DropdownChangeEvent) => props.selectTimePeriod(e)}
        />
      </div>
    );
  };

  const renderCardNumber = ()=>{
    return (
        <div className="w-5/12 mt-auto">
          <InputComponent
            type={'text'}
            label={'Número de tarjeta'}
            name={'findCardNumer'}
            value={props.cardNumberField}
            className="h-12"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setCardNumberField(e.target.value)
              props.handleKeyPress(e.target.value, 'cardNumber')
            }
            }
            placeholder="Filtrar por número de tarjeta"
          />
        </div>
      );
  }

  const renderSam = ()=>{
    return (
        <div className="w-5/12 mt-auto">
          <InputComponent
            type={'text'}
            label={'Sam'}
            name={'findCardNumer'}
            value={props.samField}
            className="h-12"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setSamField(e.target.value)
              props.handleKeyPress(e.target.value, 'sam')
            }
            }
            placeholder="Filtrar por SAM"
          />
        </div>
      );
  }


  const renderButtonSendData = () => {
    return (
      <div className="w-auto h-12 mt-auto flex flex-row space-x-3 ml-auto">
        <ButtonComponent
          label="Enviar"
          onClick={props.handlerFilters}
          className={'h-full'}
        />
        {
          <ButtonComponent
            label="Limpiar filtro"
            onClick={props.setEmptyFilters}
            className={'h-full w-40'}
          />
        }
       
      </div>
    );
  };

  const renderBtnExcel = () =>{
    return(
      <div className="w-auto h-12 mt-auto flex flex-row text-[50px] text-alternative_green content-center items-center cursor-pointer">
      {
         !props.showChart ?
          <RiBarChartBoxFill 
              onClick={async () => {
                props.setShowChart(true)
              }}
              title="Gráficas"
          /> : <RiTableLine
          onClick={async () => {
            props.setShowChart(false)
          }}
          title="Tabla de datos"
      />
      }
      </div>
    )
  }

  const renderFromDate = () => {
    return (
      <div className="!w-60 mt-auto">
        <CalendarComponent
          label={'Fecha inicio: '}
          name={'fromDate'}
          onChange={(e: any) => {
            props.setStartDateField(e.value)
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value)
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              props.setStartDateSend(formattedDate)
            } else {
                props.setStartDateField(undefined)
                props.setStartDateSend(undefined)
            }
          }
          }
          value={props.startDateField}
          placeholder="Fecha inicio"
          className=' h-12 w-full'
        />
      </div>
    );
  };


  const renderToDate = () => {
    return (
      <div className="w-60 mt-auto">
        <CalendarComponent
          label={'Fecha fin:'}
          name={'endDate'}
          onChange={(e: any) => {
            props.setEndDateField(e.value)
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value)
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              props.setEndDateSend(formattedDate)
            } else {
                props.setEndDateField(undefined)
                props.setEndDateSend(undefined)
            }
          }
          }
          value={props.endDateField}
          placeholder="Fecha fin"
          className=' h-12 w-full'
        />
      </div>
    );
  };


  return (
    <>
      <div className="w-full h-auto flex justify-between pb-3">
        <div className="flex gap-2">
          {renderSelectTimePeriod()}
          {renderFromDate()}
          {renderToDate()}
          {renderSelectDevices()}
          {renderSelectProject()}
          {renderCardNumber()}
          {renderSam()}
          {renderButtonSendData()}
        </div>
        {renderBtnExcel()}
      </div>
    </>
  );
};
