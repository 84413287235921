import { Column } from 'primereact/column';

import { LuEye, LuFileEdit, LuTrash2 } from 'react-icons/lu';

import { formatDateDDMMYY, validatePermissions } from '../../../utils/utils';
import { ButtonComponent } from './../../../components/ButtonComponen';
import { TableComponent } from './../../../components/Table';
import {
  CatalogType,
  ITableCatalogProps,
} from './../../../interfaces/interfaceCatalogs';
import { PermitsList } from './../../../permitsList';
import { BasicSelectComponent } from '../../../components/BasicSelectComponentComponent';

export const TableCatalogComponent = (props: ITableCatalogProps) => {
  const { serviceCatalog } = props;

  const renderTitle = () => {
    return (
      <div className="flex justify-between w-full h-auto pb-2">
        <span className="text-3xl font-medium">
          {serviceCatalog.tableTitle}
        </span>
        <div className="flex h-12 items-center text-xl cursor-pointer">
          {validatePermissions(PermitsList.CATALOGS_ADD) &&
          serviceCatalog.catalog !== CatalogType.SamTypes && serviceCatalog.catalog !== CatalogType.CardMapping ? (
            <ButtonComponent
              label={'Agregar'}
              className="w-auto h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
              onClick={() => {
                serviceCatalog.openTableRightMenu();
              }}
              iconPos="left"
              icon="pi pi-plus"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const renderFileUploadLabel = () => {
    return (
      <label
        htmlFor="file"
        className="w-1/4 h-12 flex cursor-pointer justify-center items-center text-white bg-official_green hover:bg-alternative_green rounded text-center">
        <span className="cursor-pointer">
          {serviceCatalog.file === null
            ? 'Subir archivo (.xlsx)'
            : 'Archivo cargado'}
        </span>
      </label>
    );
  };

  const renderImportButton = () => {
    if (serviceCatalog.file !== null) {
      return (
        <ButtonComponent
          label={'Importar'}
          className="w-1/4 h-12"
          severity="success"
          outlined={true}
          type="submit"
        />
      );
    }
    return null;
  };

  const renderFilters = () => {
    if (serviceCatalog.catalog === CatalogType.SamTypes) {
      return (
        <div className="">
          <BasicSelectComponent
            classNames="h-12"
            options={serviceCatalog.samKeyVersions}
            optionLabel={'versions'}
            name="versions"
            optionValue={'versions'}
            onChange={serviceCatalog.handleOnVersionChange}
          />
        </div>
      );
    }
  };

  const renderExportTemplateButton = () => {
    if (serviceCatalog.catalog !== CatalogType.SamTypes) {
      return (
        <ButtonComponent
          label={'Exportar plantilla'}
          className="w-1/4 h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
          onClick={serviceCatalog.exportTemplateExcel}
        />
      );
    }
    return null;
  };

  const renderExportButton = () => {
    return (
      <ButtonComponent
        label={'Exportar'}
        className="w-1/4 h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
        onClick={serviceCatalog.exportExcel}
      />
    );
  };

  const renderFiltersAndExports = () => {
    return (
      <div
        className={`flex w-full h-auto relative flex-row gap-2 pb-2 ${
          serviceCatalog.catalog !== CatalogType.SamTypes && serviceCatalog.catalog !== CatalogType.CardMapping
            ? 'justify-between'
            : 'justify-end'
        }`}>
        {validatePermissions(PermitsList.CATALOGS_IMPORT) &&
          serviceCatalog.catalog !== CatalogType.SamTypes && serviceCatalog.catalog !== CatalogType.CardMapping &&  (
            <form
              className="flex w-full h-full gap-4"
              onSubmit={serviceCatalog.handleSubmitExcel}>
              <input
                id="file"
                name="file"
                type="file"
                className="w-auto h-12 absolute opacity-0 cursor-pointer invisible"
                onChange={serviceCatalog.handleFileChange}
              />
              {renderFileUploadLabel()}
              {renderImportButton()}
            </form>
          )}
 
        {validatePermissions(PermitsList.CATALOGS_EXPORT) && serviceCatalog.catalog !== CatalogType.CardMapping && (
          <>
            {renderFilters()}
            {renderExportTemplateButton()}
            {renderExportButton()}
          </>
        )}
      </div>
    );
  };

  /**
   * Regresa la columa de acciones, icono de edicion y eliminacion de cada item
   * @param row El resgistro que se selecciono en la tabla
   */
  const renderEditIcon = (row: any) => {
    if (validatePermissions(PermitsList.CATALOGS_EDIT)) {
      return (
        <LuFileEdit
          className="cursor-pointer text-official_green"
          onClick={() => serviceCatalog.openTableRightMenu(row)}
        />
      );
    }
    return null;
  };

  const renderDeleteIcon = (row: any) => {
    const canDelete = !(
      serviceCatalog.catalog === 'Tipos de dispositivo' &&
      row?.enumType !== undefined &&
      row?.enumType !== null &&
      row?.enumType <= 8
    );

    if (validatePermissions(PermitsList.CATALOGS_DELETE) && canDelete) {
      return (
        <LuTrash2
          className="cursor-pointer text-red"
          onClick={() => serviceCatalog.openDeleteModal(row)}
        />
      );
    }
    return null;
  };

  const renderShowMappingsBySam = (row: any) => {
    return (
      <div className="flex w-full justify-around text-xl">
        <LuEye
          className="cursor-pointer text-official_green"
          onClick={() => serviceCatalog.openMeppingDetailModal(row)}
        />
      </div>
    );
  };

  const actionsRow = (row: any) => {
    return (
      <div className="flex w-full justify-around text-xl">
        {renderEditIcon(row)}
        {renderDeleteIcon(row)}
      </div>
    );
  };

  const BodyTemplate = (item: any, key: any) => {
    let textColor = item[key.field];

    if (typeof item[key.field] === 'boolean') {
      return tableBooleanValue(textColor);
    }

    if (
      key.field === 'id' &&
      item['samTypeName'] === null &&
      item['transportCompanyName'] === null
    )
      return labelTemplate(textColor);

    if( key.field === 'releaseDate' && item['releaseDate'])
      return getReleaseDate(item['releaseDate'])

    return textColor;
  };

  const getReleaseDate = (date: string) => {
    return <span>{formatDateDDMMYY(date)}</span>;
  };

  const tableBooleanValue = (text: boolean) => {
    return <span>{text ? 'Activo' : 'Inactivo'}</span>;
  };

  const labelTemplate = (text: any) => {
    return (
      <span
        className="!font-bold !text-[#f59e0b]"
        title="Advertencia: Se necesita completar los datos correspondientes">
        {text}
      </span>
    );
  };

  const generateColumns = () => {
    let listColumn = serviceCatalog.columns.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          header={column.header}
          bodyClassName={`!text-black`}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="!bg-tableHeader !text-withe!justify-between"
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filterElement={column.filterElement}
          filter={column.filter}
          body={BodyTemplate}
        />
      );
    });
    if (serviceCatalog.catalog !== CatalogType.SamTypes && serviceCatalog.catalog !== CatalogType.CardMapping) {
      listColumn.push(
        <Column
          key={serviceCatalog.columns.length + 1}
          header={'Acciones'}
          body={actionsRow}
        />
      );
    } else if (serviceCatalog.catalog === CatalogType.SamTypes || serviceCatalog.catalog === CatalogType.CardMapping) {
      listColumn.push(
        <Column
          key={serviceCatalog.columns.length + 1}
          header={'Ver mapeo'}
          body={renderShowMappingsBySam}
        />
      );
    }
    return listColumn;
  };

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row ">
        <TableComponent
          value={serviceCatalog.dataCatalogTable}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={serviceCatalog.setSort}
          sort={serviceCatalog.sort}>
          {generateColumns()}
        </TableComponent>
      </div>
    );
  };

  return (
    <>
      {serviceCatalog.catalog !== CatalogType.InitMenu ? (
        <>
          {renderTitle()}
          {renderFiltersAndExports()}
          {renderContent()}
        </>
      ) : (
        <span className="text-3xl">Seleccione un catálogo</span>
      )}
    </>
  );
};
