import { PopUpComponent } from '../../../components/PopUpComponent';
import { ICatalogProvider } from '../../../interfaces/service/CatalogServiceManagerInterface';

interface IDisplayMappingSamProps {
  serviceCatalog: ICatalogProvider;
}

export const DisplayMappingJsonDataModal = (props: IDisplayMappingSamProps) => {
  const { serviceCatalog } = props;

  let mapping: any;
  let mappingInJson: any[] = [];
  let title: string = 'Mapeo';

  if (serviceCatalog?.dataRow?.Mapping) {
    mapping = serviceCatalog?.dataRow?.Mapping[0]?.samMapping;
    mappingInJson = JSON.parse(mapping);
    title = 'Mapeo de SAM';
  }

  else if (serviceCatalog?.dataRow?.isActive) {
    mapping = serviceCatalog?.dataRow?.cardMapping;
    mappingInJson.push(JSON.parse(mapping));
    title = 'Mapeo de tarjetas';
  }

  const bodyModalContent = () => {
    if (!mapping || mappingInJson.length === 0) {
      return <div>No hay datos disponibles.</div>;
    } else if (mapping) {
      return (
        <div className="flex w-full h-full gap-3 p-4">
          {mappingInJson.map((item: any, index: number) => (
            <div className="flex" key={index}>
              <pre className="select-none	">{JSON.stringify(item, null, 2)}</pre>
            </div>
          ))}
          <div></div>
        </div>
      );
    }
  };

  return (
    <>
      {serviceCatalog?.dataRow?.Mapping || serviceCatalog?.dataRow?.cardMapping ? (
        <PopUpComponent
          header={title}
          showPopUp={serviceCatalog.showDisplayMappingModal}
          onSubmit={() => serviceCatalog.deleteCatalogItem()}
          onClosePopUp={() => serviceCatalog.closeMappingDetailModal()}
          body={bodyModalContent()}
        />
      ) : (
        <></>
      )}
    </>
  );
};
