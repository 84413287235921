import { Column } from 'primereact/column';
import { useContext, useEffect, useState } from 'react';
import { LuFileEdit, LuTrash2 } from 'react-icons/lu';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { TableComponent } from '../../../components/Table';
import { useUserManager } from '../../../hooks/usersManager/useUserManager';
import { PermitsList } from '../../../permitsList';
import { validatePermissions } from '../../../utils/utils';
import { DialogPopUpComponent } from './../../../components/DialogPopUpComponent';
import {
  IUsers,
  IUsersManagerTableProps,
} from './../../../interfaces/interfaceUserManager';
import { StoreContext } from '../../../context/Provider';

export const UsersManagerTable = (props: IUsersManagerTableProps) => {
  const { setOpenRightMenu, setDataRow, selectMenu, setUpdateTable } = props;
  const context = useContext(StoreContext);
  const {
    getUserManagerState,
  } = context;
  //States Zone
  const [userList, setUserLista] = useState<IUsers[]>([]);
  const [userSelected, setUserSelected] = useState<IUsers>();
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  //const [menuTitle, setMenuTitle] = useState<string>('');
  let menuTitle = '';

  const [showDeleteModalUser, setShowDeleteModalUser] =
    useState<boolean>(false);

  let usersCopy: IUsers[];

  //Hooks Zone
  const { getUserManager } = useUserManager();
  const { deleteUserManager } = useUserManager();

  //Table Zone

  useEffect(() => {
    const getData = async () => {
      let response = await getUserManager();
      if (response.status === 200) {
        setUserLista(response.data);
        usersCopy = response.data;
      }
    };

    getData();
  }, []);


  useEffect(() => {
    setUserLista(getUserManagerState.data);
    usersCopy =getUserManagerState.data;
  }, [getUserManagerState.data]);


  /**
   *Actualiza la tabla sin necesidad de hacer una peticion a BD
   * @param elementUpdated El objeto que se va a agregar a la tabla
   */
  const updateTable = (elementUpdated: any) => {
    let copyUserList: any = userList;

    if (menuTitle === 'Actualizar') {
      if (elementUpdated !== null && elementUpdated !== undefined) {
        let users = usersCopy.map((item: any) => {
          if (item.userId === elementUpdated.userId) {
            return elementUpdated;
          }
          return item;
        });
        setUserLista(users);
      }
    } else {
      copyUserList.push(elementUpdated);
      setUserLista(copyUserList);
    }

    setOpenRightMenu(false);
    selectMenu('');
  };

  function openRightMenu(row: IUsers = {}, title: string = 'Agregar') {
    menuTitle = title;
    setDataRow(row);
    setOpenRightMenu(true);
    selectMenu(menuTitle);
    setUpdateTable(() => updateTable);
  }

  const openDeleteModalUser = (user: IUsers) => {
    setShowDeleteModalUser(true);
    setUserSelected(user);
  };

  /**
   * Regresa la columa de acciones, icono de edicion y eliminacion de cada item
   * @param row El resgistro que se selecciono en la tabla
   */
  const actionsRow = (row: IUsers) => {
    return (
      <div className="flex w-full justify-around text-xl">
        {validatePermissions(PermitsList.SEC_USERS_EDIT) ? (
          <LuFileEdit
            className="cursor-pointer text-official_green"
            onClick={() => openRightMenu(row, 'Actualizar')}
          />
        ) : (
          <></>
        )}
        {/* {validatePermissions(PermitsList.SEC_USERS_DELETE) ? ( // EL permiso esta actualmente eliminado del excel por falta de bits. Es sig versiones es posible que se active */}
        <LuTrash2
          className="cursor-pointer text-red"
          onClick={() => openDeleteModalUser(row)}
        />
        {/*   ) : (
          <></>
        )} */}
      </div>
    );
  };

  let columnList = [
    { field: 'firstName', header: 'Nombre', sortable: true },
    { field: 'lastName', header: 'Apellidos', sortable: true },
    { field: 'roleName', header: 'Rol', sortable: true },
    { field: 'phone', header: 'Teléfono', sortable: true },
    { field: 'email', header: 'Correo', sortable: true },
    { field: 'jobPosition', header: 'Puesto', sortable: true },
    { field: 'acciones', header: 'Acciones', sortable: true, body: actionsRow },
  ];

  const [columns] = useState<any>(columnList);

  const deleteUser = async () => {
    if (userSelected !== undefined) {
      let response = await deleteUserManager(userSelected['userId'] || '');
      if (response.status === 204) {
        let copyUserList: any = userList;
        let users = copyUserList.filter(
          (item: IUsers) => item.userId !== userSelected.userId
        );
        setUserLista(users);
      }
    }
    setShowDeleteModalUser(false);
  };

  const bodyModalContent = () => {
    let name =
      userSelected !== undefined && userSelected['name']
        ? userSelected['name']
        : '';
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        ¿Deseas eliminar al usuario '<b>{name}</b>' seleccionado?
      </div>
    );
  };

  const footerModalContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label="Cancelar"
          onClick={() => setShowDeleteModalUser(false)}
          className="p-button-text !bg-deleteButton !text-white"
        />
        <ButtonComponent label="Aceptar" onClick={() => deleteUser()} />
      </div>
    );
  };

  function closeModal() {
    setShowDeleteModalUser(false);
  }

  return (
    <>
      <div className="h-auto pb-3 w-full flex flex-row justify-end align-middle relative">
        {validatePermissions(PermitsList.SEC_USERS_ADD) ? (
          <ButtonComponent
            label="Agregar usuario"
            onClick={openRightMenu}
            className={'h-12'}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="h-full w-full flex flex-row relative">
        <TableComponent
          value={userList}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={setSort}
          sort={sort}>
          {columns.map((column: any, index: any) => {
            const key = index.toString();
            return (
              <Column
                key={key}
                field={column.field}
                header={column.header}
                bodyClassName={'!text-black'}
                style={{
                  flexGrow: 1,
                  flexBasis: '100px',
                }}
                headerClassName="  !bg-tableHeader !text-withe!justify-between "
                sortable={column.sortable}
                filterMenuStyle={{ width: '14rem' }}
                filterElement={column.filterElement}
                filter={column.filter}
                body={column.body}
              />
            );
          })}
        </TableComponent>
      </div>

      {showDeleteModalUser ? (
        <DialogPopUpComponent
          show={true}
          header={'Eliminar Usuario'}
          body={bodyModalContent()}
          footer={footerModalContent()}
          close={closeModal}
        />
      ) : (
        false
      )}
    </>
  );
};
