import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import {
  DEFAULT_PAGE,
  GRID_PAGES,
  ROWS_PER_PAGE,
} from '../constants/TableConstants';

interface ITableProps {
  name?: string;
  className?: string;
  columns?: any[];
  data?: any[];
  filters?: any;
  handlerFilters?: any;
  paginator?: boolean;
  selectionChange?: any;
  size?: 'small' | 'normal' | 'large' | undefined;
  id?: string;
  props?: any;
  children?: any;
  resizableColumns?: boolean;
  sort?: any;
  rows?: any;
  setSort?: any;
  doubleClick?: any;
  value?: any;
  sortField?: any;
  responsiveLayout?: string;
  rowsPerPageOptions?: boolean;
  globalFilterFields?: any;
  selection?: any;
  onSelectionChange?: any;
  reorderableColumns?: boolean;
  onColReorder?: any;
  OnEmptyMessage?: string;
  selectionMode?: string;
  loading?: boolean;
  rowExpansionTemplate?: any;
  onRowToggle?: any;
  expandedRows?: any;
  onPage?: any
  groupRowsBy? : any
  rowGroupHeaderTemplate?:any
  rowGroupMode?: any
  expandableRowGroups ? : any;
  filterDisplay?:string
}

export const TableComponent = (tableProps: ITableProps) => {
  const {
    id,
    size,
    children,
    data,
    handlerFilters,
    filters,
    className,
    props,
    globalFilterFields,
    sort,
    rows,
    setSort,
    doubleClick,
    value,
    sortField,
    responsiveLayout,
    rowsPerPageOptions,
    onSelectionChange,
    selection,
    reorderableColumns,
    onColReorder,
    OnEmptyMessage = 'No se encontraron registros',
    paginator,
    selectionMode,
    loading,
    rowExpansionTemplate,
    onRowToggle,
    expandedRows,
    onPage,
    groupRowsBy,
    rowGroupHeaderTemplate,
    rowGroupMode,
    expandableRowGroups,
    filterDisplay
  } = tableProps;

  const totalsRows = rows !== undefined ? rows : ROWS_PER_PAGE;

  const [first] = useState(DEFAULT_PAGE);
  const [rowsNumber] = useState(totalsRows);
  const [dataSort] = useState(sort !== undefined ? [...sort] : []);

  return (
    <div className={className ? className : "flex w-full h-full absolute"}>
      <DataTable
        expandableRowGroups={expandableRowGroups} 
        groupRowsBy={groupRowsBy}
        rowGroupHeaderTemplate={rowGroupHeaderTemplate}
        rowGroupMode={rowGroupMode}
        selection={selection}
        value={value}
        first={first}
        rows={rowsNumber}
        sortField={sortField}
        onPage={onPage}
        paginatorClassName="!border-t !border-border !justify-start !text-xs"
        onSelectionChange={onSelectionChange}
        loading={loading}
        //rowHover
        id={id !== undefined ? id : 'testId'}
        removableSort
        //responsiveLayout={responsiveLayout}
        globalFilterFields={globalFilterFields}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown "
        filters={filters}
        filterDisplay={filterDisplay}
        rowsPerPageOptions={rowsPerPageOptions ? GRID_PAGES : rowsPerPageOptions}
        resizableColumns
        columnResizeMode="fit"
        sortOrder={1}
        paginator={paginator ? true : false}
        scrollable
        scrollHeight="flex"
        // scrollDirection="both"
        stripedRows
        //	showGridlines
        tableClassName={'!absolute !top-0 !left-0 !right-0 !bottom-0 '}
        className="flex w-full h-full relative"
        //rowClassName="hover:!bg-[#3b82f6] !border-r !border-secondary-PCS004 "
        onRowDoubleClick={(e: any) => {
          if (doubleClick) {
            doubleClick(e.data);
          }
        }}
        //loading={loading || loadingState}
        onSort={(e: any) => {
          if (e?.multiSortMeta.length === 0) {
            setSort(dataSort);
          } else {
            setSort(e.multiSortMeta);
          }
        }}
        multiSortMeta={sort}
        sortMode="multiple"
        {...props}
        currentPageReportTemplate="Total de registros {totalRecords}"
        reorderableColumns={reorderableColumns}
        onColReorder={onColReorder}
        emptyMessage={OnEmptyMessage}
        selectionMode={selectionMode}
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={onRowToggle}>
        {children}
      </DataTable>
    </div>
  );
};
