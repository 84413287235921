import { CatalogType } from "../../../../interfaces/interfaceCatalogs";
import { BsBicycle, BsBuilding, BsBusFront, BsCardList, BsCashCoin, BsClipboard2Data, BsFillCalculatorFill, BsFillPersonVcardFill, BsLamp, BsMemory, BsPinMapFill, BsSdCard } from "react-icons/bs";

export function elementList() {
  return [
    {
      name: CatalogType.TransportCompanies,
      icon: <BsMemory />,
      id: 1,
      document_name: 'Compañías de transporte',
    },
    {
      name: CatalogType.Stations,
      icon: <BsLamp />,
      document_name: 'Estaciones'
    },
    {
      name: CatalogType.Locations,
      icon: <BsPinMapFill />,
      document_name: 'Locaciones',
    },
    /*     {
          name: 'Módulos PC',
          icon: <BsPcDisplayHorizontal />,
          document_name: 'Módulos PC',
        }, */
    {
      name: CatalogType.EconomicNumbers,
      icon: <BsBusFront />,
      document_name: 'Números económicos',
    },
    {
      name: CatalogType.Operators,
      icon: <BsBuilding />,
      document_name: 'Operadores tecnológicos',
    },
    {
      name: CatalogType.PointOfSales,
      icon: <BsCashCoin />,
      document_name: 'Puntos de venta',
    },
    {
      name: CatalogType.Projects,
      icon: <BsClipboard2Data />,
      document_name: 'Proyectos',
    },
    {
      name: CatalogType.Routes,
      icon: <BsBicycle />,
      document_name: 'Rutas'
    },
    {
      name: CatalogType.DeviceTypes,
      icon: <BsSdCard />,
      document_name: 'Tipos de dispositivo',
    },
    {
      name: CatalogType.SamTypes,
      icon: <BsFillCalculatorFill />,
      document_name: 'Tipos de SAM',
    },
    {
      name: CatalogType.ProfileCards,
      icon: <BsFillPersonVcardFill />,
      document_name: CatalogType.ProfileCards,
    },
    {
      name: CatalogType.CardMapping,
      icon: <BsCardList  />,
      document_name: CatalogType.CardMapping,
    }
  ]
};