import { ICatalogProvider } from '../../../interfaces/service/CatalogServiceManagerInterface';
import { PopUpComponent } from '../../../components/PopUpComponent';

interface IConfirmCloseRightMenu {
  serviceCatalog: ICatalogProvider;
}

export const ConfirmCloseRightMenu = (props: IConfirmCloseRightMenu) => {
  const {
    serviceCatalog
  } = props;

  const bodyModalContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <p>
          Va a cerrar el formulario actual y la información ingresada se perderá si no se guarda, ¿Quiere salir?       </p>
      </div>
    );
  };

  return (
    <div>
      <PopUpComponent
        header={"¿Está seguro de querer cerrar el formulario?"}
        showPopUp={serviceCatalog.showCloseRightMenuModal}
        onSubmit={() => serviceCatalog.confirmCloseRightMenuModal()}
        onClosePopUp={() => serviceCatalog.closeConfirmCloseRightMenuModal()}
        footer={true}
        body={bodyModalContent()}
      />
    </div>
  );
};
