import { createContext, useContext, useEffect, useState } from "react";
import { IHistoryCardProvider } from "../../interfaces/service/HistoryCardServiceManagerInterface";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { ITimePeriod } from "../../interfaces/ITimePeriod";
import { DropdownChangeEvent } from "primereact/dropdown";
import { DefaultColumns } from "../../pages/HistoryCards/data/DefaultColumns";
import { StoreContext } from "../../context/Provider";
import { useDeviceTypesCatalogs } from "../../hooks/catalogs/useDeviceTypesCatalogs";
import { useHistoryCard } from "../../hooks/historyCard/useHistoryCard";
import { ITypeChart } from "../../interfaces/ITypeChart";
import { DefaultTypeCharts } from "../../pages/HistoryCards/data/DefaultTypeCharts";
import { ITypeReport } from "../../interfaces/ITypeReport";
import { usegetProjectsCatalogs } from "../../hooks/catalogs/useProjectsCatalog";
import { string } from "yup";

export const HistoryCardContextSM = createContext<IHistoryCardProvider>({} as IHistoryCardProvider);

export const HistoryCardProvider = (props: any) => {
    const [dataTable, setDataTable] = useState<any[]>([]);
    const [dataTableInternal, setDataTableInternal] = useState<any[]>([]);
    const [dataChart, setDataChart] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [projectsSelected, setProjectsSelected] = useState<any[]>([]);
    const [startDateField, setStartDateField] = useState<any>();
    const [endDateField, setEndDateField] = useState<any>();
    const [startDateSend, setStartDateSend] = useState<any>('');
    const [endDateSend, setEndDateSend] = useState<any>('');
    const [cardNumberField, setCardNumberField] = useState<any>('');
    const [samField, setSamField] = useState<any>('');
    const [devices, setDevices] = useState<any[]>([]);
    const [devicesSelected, setDevicesSelected] = useState<any[]>([]);
    const [sort, setSort] = useState([{ field: '', order: -1 }]);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    const [showChart, setShowChart] = useState<boolean>(false);

    const [timePeriod] = useState<ITimePeriod[]>(
        [{id:1,name:"Día"},
        {id:2, name:"Semana"},
        {id:3,name:"Mes"}]
    );

    const [typeReport] = useState<ITypeReport[]>(
        [
        {id:1,name:"Transacciones de las tarjetas por perfil y fecha"},
        {id:2, name:"Matriz de transacciones"}
        ]
    );

    const [typeReportSelected, setTypeReportSelected] = useState<ITypeReport>();
    const [totalCount, setTotalCount] = useState<number>();
    const [typeChart] = useState<ITypeChart[]>(DefaultTypeCharts);
    const [typeChartSelected, setTypeChartSelected] = useState<ITypeChart>();
    const [timePeriodSelected, setTimePeriodSelected] = useState<ITimePeriod>();
    const [defaultColumnsTable] = useState(DefaultColumns);

    const { getDeviceTypesCatalogState,historyCardStateContext, getProjectsCatalogState} = useContext(StoreContext);

    const {
        getProjectsCatalog,
      } = usegetProjectsCatalogs();

    

    const {
        getDeviceTypesCatalog,
      } = useDeviceTypesCatalogs();
    const {
        getPrepayCards
    } = useHistoryCard();

    useEffect(()=>{
        const today = new Date();
        const currentDate = formatDate(today);

        // Calcular la fecha 2 meses atrás
        const initMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const initMonthAgoDate = formatDate(initMonth);

        getDeviceTypes()
        setTimePeriodSelected(timePeriod[0])
        getPrepayCards(`?typePeriod=1&typeReport=1&endDate=${currentDate}&startDate=${initMonthAgoDate}`);
        setTypeChartSelected(typeChart[0]);
        setTypeReportSelected(typeReport[0]);
        getProjectsCatalog();
    },[])


    const formatDate = (date:any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes empieza desde 0
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
    useEffect(() => {
        if (
            getDeviceTypesCatalogState.state.data !== null
        ) {
            setDevices(getDeviceTypesCatalogState.state.data);
        }
      }, [
        getDeviceTypesCatalogState.state,
      ]);
    
    useEffect(()=>{
        if(historyCardStateContext.state.data!= null && historyCardStateContext.state.data !== undefined){
            let {data, chart,totalCount} = historyCardStateContext.state.data;
            setDataTable(data);
            setDataTableInternal(data);
            let addTensionDataset = chart?.datasets?.map((x:any)=> {return {...x, "tension": .4}}) ?? []
            if(chart?.datasets !== null&& chart?.datasets !== undefined){
                chart.datasets = addTensionDataset;
            }

            setDataChart(chart);
            setTotalCount(totalCount ?? 0)
        }
    },[historyCardStateContext.state.data])

        
    useEffect(()=>{
        setProjects(getProjectsCatalogState.state.data)
    },[getProjectsCatalogState])

  
    function selectDevices(e: MultiSelectChangeEvent) {
        setDevicesSelected(e.value);
    }

    function selectProject(e: MultiSelectChangeEvent) {
        setProjectsSelected(e.value);
    }


    function selectTimePeriod(e: DropdownChangeEvent) {
        setTimePeriodSelected(e.value);
    }

    function setEmptyFilters() {
        setStartDateField(undefined)
        setStartDateSend(undefined)
        setProjectsSelected([])
        setEndDateField(undefined)
        setEndDateSend(undefined)

        setDevicesSelected([])
        setCardNumberField('')
        setSamField('')
    }


    async function handleKeyPress(value:string, propertie: string) {
        setExpandedRows([])
       if((cardNumberField === '' || cardNumberField.length === 0) && (samField === '' || samField.length === 0))
       {
            setDataTable(dataTableInternal);
            return;
       }

       if(
        cardNumberField !== '' && cardNumberField !== undefined && cardNumberField.length > 0  &&
        samField !== '' && samField !== undefined && samField.length > 0
        )
       {
        let dataFinal = dataTableInternal.filter((x:any)=> 
            x['cardNumber']?.toString().toLowerCase().includes(cardNumberField.toLowerCase()) &&
            x['sam']?.toString().toLowerCase().includes(samField.toLowerCase())
    
        )

        setDataTable(dataFinal);
        return;
       }

       let dataFinal = dataTableInternal.filter((x:any)=> x[propertie]?.toString().toLowerCase().includes(value.toLowerCase()))
       setDataTable(dataFinal);
    }

    function containValue(value:any) {
        return value !== undefined && value !== null && value !== "";
    }
    
    function containValueArray(value:any) {
        return value !== undefined && value !== null && value !== ""&& value.length > 0;
    }

    async function handlerFilters() {
        var query = "?"
        if(containValue(startDateSend)){
            query+=`&startDate=${startDateSend}`
        }
        if(containValue(endDateSend)){
            query+=`&endDate=${endDateSend}`
        }
        if(containValue(cardNumberField)){
            query+=`&cardNumber=${cardNumberField}`
        }
        if(containValue(samField)){
            query+=`&sam=${samField}`
        }
        if(containValue(timePeriodSelected)){
            query+=`&typePeriod=${timePeriodSelected?.id}`
        }

        if(containValue(typeReportSelected)){
            query+=`&typeReport=${typeReportSelected?.id}`
        }

        if(containValueArray(devicesSelected)){
            devicesSelected.forEach(element => {
                query+=`&device=${element.name}`
            });
        }

        if(containValueArray(projectsSelected)){
            projectsSelected.forEach(element => {
                query+=`&project=${element.name}`
            });
        }

        setExpandedRows([])
        getPrepayCards(query);
    }  

    async function getDeviceTypes() {
        return await getDeviceTypesCatalog();
      };

    return (
        <HistoryCardContextSM.Provider
          value={{
           dataTable,
           startDateField,
           endDateField,
           cardNumberField,
           devices,
           devicesSelected,
           timePeriod,
           timePeriodSelected,
           startDateSend,
           endDateSend,
           sort,
           defaultColumnsTable,
           expandedRows, 
           samField, 
           showChart, 
           typeChart,
           typeChartSelected, 
           typeReport,
           dataChart, 
           typeReportSelected, 
           totalCount,
           projects,
           projectsSelected, 
           
           setProjectsSelected,
           setTypeReportSelected,
           setDataChart,
           setTypeChartSelected,
           setShowChart,
           setSamField,
           setExpandedRows,
           setDataTable,
           setStartDateField,
           setEndDateField,
           setCardNumberField,
           setDevices,
           setDevicesSelected,
           setTimePeriodSelected,
           setEndDateSend,
           setStartDateSend,
           setSort,

           selectDevices,
           handlerFilters,
           selectTimePeriod,
           setEmptyFilters,
           selectProject,

           handleKeyPress
          }}
        >
          {props.children}
        </HistoryCardContextSM.Provider>
      );
}

