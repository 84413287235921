import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { StoreContext } from '../context/Provider';
import { NavBar } from './components/NavBar/header';
import { ProgressSpinner } from 'primereact/progressspinner';

///import { RightMenuConfigurationComponent } from '../components/RightMenuConfigurationComponent';
//import { ThemeContext } from '../context/ThemeContext';
export const MainLayout: FunctionComponent = () => {
  const context = useContext(StoreContext);
  const { setOpenRightMenu, openRightMenu } = context;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const rightMenuRef = useRef<HTMLDivElement>(null);

  const {
    getUserManagerState,
    postUserManagerState,
    deleteUserManagerState,
    patchUserManagerState,
    putTransportCompaniesCatalogState,
    catalogSamCounter,
    countersFromSamIdStateContext,
    countersFromSamTypeStateContext,
    deleteDeviceTypesCatalogState,
    deleteEconomicNumbersCatalogState,
    deleteLocationsCatalogState,
    deleteOperatorsCatalogState,
    deletePosCatalogState,
    deleteProjectsCatalogState,
    deleteRoleState,
    deleteRoutesCatalogState,
    deleteStationsCatalogState,
    deleteTransportCompaniesCatalogState,
    getCatalogDeviceState,
    putStationsCatalogState,
    putRoutesCatalogState,
    putRechargeCounterState,
    putPosCatalogState,
    putProjectsCatalogState,
    putOperatorsCatalogState,
    putLocationsCatalogState,
    putEditSamState,
    putEconomicNumbersCatalogState,
    putDeviceTypesCatalogState,
    postTransportCompaniesCatalogState,
    postStationsCatalogState,
    postRoutesCatalogState,
    postRoleState,
    postProjectsCatalogState,
    getCatalogOperatorsState,
    getCatalogProjectsState,
    postPosCatalogState,
    postOperatorsCatalogState,
    postLocationsCatalogState,
    postExportDeviceTypesCatalogState,
    postEconomicNumbersCatalogState,
    postDeviceTypesCatalogState,
    patchSamCounter,
    patchRoleState,
    patchEditSamState,
    inventoryToExcelStateContext,
    inventoryStateContext,
    getStationsCatalogState,
    getDeviceTypesCatalogState,
    getEconomicNumbersCatalogState,
    getExportCatalogState,
    getLocationCatalogState,
    getTransportCompaniesCatalogState,
    getSettingsState,
    getSamTypesState,
    getOperatorsCatalogState,
    getSamByIdState,
    getPcModulesCatalogState,
    getPointsOfSalesCatalogState,
    getProjectsCatalogState,
    getRolesState,
    getRoutesCatalogState,
    loadingState,
    getProfileCardCatalogState,
    postProfileCardCatalogState,
    putProfileCardCatalogState,
    deleteProfileCardCatalogState,
  } = context;

  const loading =
    getUserManagerState.loading ||
    postUserManagerState.loading ||
    deleteUserManagerState.loading ||
    patchUserManagerState.loading ||
    putTransportCompaniesCatalogState.state.loading ||
    catalogSamCounter.state.loading ||
    countersFromSamIdStateContext.state.loading ||
    countersFromSamTypeStateContext.state.loading ||
    deleteDeviceTypesCatalogState.state.loading ||
    deleteEconomicNumbersCatalogState.state.loading ||
    deleteLocationsCatalogState.state.loading ||
    deleteOperatorsCatalogState.state.loading ||
    deletePosCatalogState.state.loading ||
    deleteProjectsCatalogState.state.loading ||
    deleteRoleState.loading ||
    deleteRoutesCatalogState.state.loading ||
    deleteStationsCatalogState.state.loading ||
    deleteTransportCompaniesCatalogState.state.loading ||
    getCatalogDeviceState.state.loading ||
    putStationsCatalogState.state.loading ||
    putRoutesCatalogState.state.loading ||
    putRechargeCounterState.state.loading ||
    putPosCatalogState.state.loading ||
    putProjectsCatalogState.state.loading ||
    putOperatorsCatalogState.state.loading ||
    putLocationsCatalogState.state.loading ||
    putEditSamState.state.loading ||
    putEconomicNumbersCatalogState.state.loading ||
    putDeviceTypesCatalogState.state.loading ||
    postTransportCompaniesCatalogState.state.loading ||
    postStationsCatalogState.state.loading ||
    postRoutesCatalogState.state.loading ||
    postRoleState.loading ||
    postProjectsCatalogState.state.loading ||
    getCatalogOperatorsState.state.loading ||
    getCatalogProjectsState.state.loading ||
    postPosCatalogState.state.loading ||
    postOperatorsCatalogState.state.loading ||
    postLocationsCatalogState.state.loading ||
    postExportDeviceTypesCatalogState.state.loading ||
    postEconomicNumbersCatalogState.state.loading ||
    postDeviceTypesCatalogState.state.loading ||
    patchSamCounter.state.loading ||
    patchRoleState.loading ||
    patchEditSamState.state.loading ||
    inventoryToExcelStateContext.state.loading ||
    inventoryStateContext.state.loading ||
    getStationsCatalogState.state.loading ||
    getDeviceTypesCatalogState.state.loading ||
    getEconomicNumbersCatalogState.state.loading ||
    getExportCatalogState.loading ||
    getLocationCatalogState.state.loading ||
    getTransportCompaniesCatalogState.state.loading ||
    getSettingsState.loading ||
    getSamTypesState.loading ||
    getOperatorsCatalogState.state.loading ||
    getSamByIdState.state.loading ||
    getPcModulesCatalogState.loading ||
    getPointsOfSalesCatalogState.state.loading ||
    getProjectsCatalogState.state.loading ||
    getRolesState.loading ||
    getRoutesCatalogState.state.loading ||
    getProfileCardCatalogState.state.loading ||
    postProfileCardCatalogState.state.loading ||
    putProfileCardCatalogState.state.loading ||
    deleteProfileCardCatalogState.state.loading ||
    loadingState;


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        rightMenuRef.current &&
        !rightMenuRef.current.contains(event.target as Node) &&
        userButtonRef.current &&
        !userButtonRef.current.contains(event.target as Node) &&
        !document.querySelector('.p-dropdown-panel')?.contains(target) &&
        !document.querySelector('.p-multiselect-panel')?.contains(target) &&
        !document.querySelector('.p-dialog-mask')?.contains(target) &&
        !document.querySelector('.p-datepicker')?.contains(target) &&
        !document.querySelector('.p-multiselect-item')?.contains(target) &&
        !document.querySelector('.p-dropdown-item')?.contains(target) &&
        !document.querySelector('.p-checkbox-box')?.contains(target) &&
        !document.querySelector('.p-checkbox-icon')?.contains(target) &&
        !document.querySelector('.Toastify')?.contains(target)
      ) {
        setOpenRightMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const userMenuRef = useRef<HTMLDivElement>(null);
  const userButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideUser = (event: MouseEvent) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideUser);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideUser);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideUser = (event: MouseEvent) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideUser);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideUser);
    };
  }, []);

  return (
    <div className="flex flex-col w-full h-screen relative">
      {
        !loading ? <></> :
          <div className='!z-50 flex flex-col w-full h-screen !absolute justify-center items-center' style={{ backgroundColor: 'rgba(128, 128, 128, .5)' }}>
            <ProgressSpinner
              style={{ width: '100px', height: '100px', zIndex: '80' }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          </div>
      }
      <NavBar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        userMenuRef={userMenuRef}
        userButtonRef={userButtonRef}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
      //userButtonRef={userButtonRef}
      />
      <div className="flex h-full w-full relative ">
        <Outlet />
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          //closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};
