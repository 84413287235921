import { deleteProfileCardCatalogRed } from "../../../business/Reducers/catalogs/profile_cards/deleteProfileCardCatalogRed"
import { getProfileCardCatalogRed } from "../../../business/Reducers/catalogs/profile_cards/getProfileCardCatalogRed"
import { postProfileCardCatalogRed } from "../../../business/Reducers/catalogs/profile_cards/postProfileCardCatalogRed"
import { putProfileCardCatalogRed } from "../../../business/Reducers/catalogs/profile_cards/putProfileCardCatalogRed"
import { useReducer } from "react"
import { deleteDeviceTypesCatalogRed } from "../../../business/Reducers/catalogs/deviceTypes/deleteDeviceTypesCatalogRed"
import { getDeviceTypesCatalogRed } from "../../../business/Reducers/catalogs/deviceTypes/getDeviceTypesCatalogRed"
import { postDeviceTypesCatalogRed } from "../../../business/Reducers/catalogs/deviceTypes/postDeviceTypesCatalogRed"
import { putDeviceTypesCatalogRed } from "../../../business/Reducers/catalogs/deviceTypes/putDeviceTypesCatalogRed"
import { deleteEconomicNumbersCatalogRed } from "../../../business/Reducers/catalogs/economicNumbers/deleteEconomicNumbersCatalogRed"
import { getEconomicNumbersCatalogRed } from "../../../business/Reducers/catalogs/economicNumbers/getEconomicNumbersCatalogRed"
import { postEconomicNumbersCatalogRed } from "../../../business/Reducers/catalogs/economicNumbers/postEconomicNumbersCatalogRed"
import { putEconomicNumbersCatalogRed } from "../../../business/Reducers/catalogs/economicNumbers/putEconomicNumbersCatalogRed"
import { deleteLocationsCatalogRed } from "../../../business/Reducers/catalogs/locations/deleteLocationsCatalogRed"
import { getLocationsCatalogRed } from "../../../business/Reducers/catalogs/locations/getLocationsCatalogRed"
import { postLocationsCatalogRed } from "../../../business/Reducers/catalogs/locations/postLocationsCatalogRed"
import { putLocationsCatalogRed } from "../../../business/Reducers/catalogs/locations/putLocationsCatalogRed"
import { deleteOperatorsCatalogRed } from "../../../business/Reducers/catalogs/operators/deleteOperatorsCatalogRed"
import { getOperatorsCatalogRed } from "../../../business/Reducers/catalogs/operators/getOperatorsCatalogRed"
import { postOperatorsCatalogRed } from "../../../business/Reducers/catalogs/operators/postOperatorsCatalogRed"
import { putOperatorsCatalogRed } from "../../../business/Reducers/catalogs/operators/putOperatorsCatalogRed"
import { deletePosCatalogRed } from "../../../business/Reducers/catalogs/points_of_sales/deletePointsOfSalesCatalogRed"
import { getPointsOfSalesCatalogRed } from "../../../business/Reducers/catalogs/points_of_sales/getPointsOfSalesCatalogRed"
import { postPosCatalogRed } from "../../../business/Reducers/catalogs/points_of_sales/postPointsOfSalesCatalogRed"
import { putPosCatalogRed } from "../../../business/Reducers/catalogs/points_of_sales/putPointsOfSalesCatalogRed"
import { postExportDeviceTypesCatalogRed } from "../../../business/Reducers/catalogs/postExportDeviceTypesCatalogRed"
import { deleteProjectsCatalogRed } from "../../../business/Reducers/catalogs/projects/deleteProjectsCatalogRed"
import { getProjectsCatalogRed } from "../../../business/Reducers/catalogs/projects/getProjectsCatalogRed"
import { postProjectsCatalogRed } from "../../../business/Reducers/catalogs/projects/postProjectsCatalogRed"
import { putProjectsCatalogRed } from "../../../business/Reducers/catalogs/projects/putProjectsCatalogRed"
import { deleteRoutesCatalogRed } from "../../../business/Reducers/catalogs/routes/deleteRoutesCatalogRed"
import { getRoutesCatalogRed } from "../../../business/Reducers/catalogs/routes/getRoutesCatalogRed"
import { postRoutesCatalogRed } from "../../../business/Reducers/catalogs/routes/postRoutesCatalogRed"
import { putRoutesCatalogRed } from "../../../business/Reducers/catalogs/routes/putRoutesCatalogRed"
import { deleteStationsCatalogRed } from "../../../business/Reducers/catalogs/stations/deleteStationsCatalogRed"
import { getStationsCatalogRed } from "../../../business/Reducers/catalogs/stations/getStationsCatalogRed"
import { postStationsCatalogRed } from "../../../business/Reducers/catalogs/stations/postStationsCatalogRed"
import { putStationsCatalogRed } from "../../../business/Reducers/catalogs/stations/putStationsCatalogRed"
import { deleteTransportCompaniesCatalogRed } from "../../../business/Reducers/catalogs/transport_companies/deleteTransportCompaniesCatalogRed"
import { getTransportCompaniesCatalogRed } from "../../../business/Reducers/catalogs/transport_companies/getTransportCompaniesCatalogRed"
import { postTransportCompaniesCatalogRed } from "../../../business/Reducers/catalogs/transport_companies/postTransportCompaniesCatalogRed"
import { putTransportCompaniesCatalogRed } from "../../../business/Reducers/catalogs/transport_companies/putTransportCompaniesCatalogRed"
import { getSamKeyVersions } from "../../../business/Reducers/counterFromSam/getSamKeyVersions"
import { getSamCounterCatalog, patchSamCounterCatalog } from "../../../business/Reducers/sam/samCountersRed"
import { getInventory_Red } from "../../Reducers/inventory/get-red"
import { globalInitialState } from "../initialStateGlobal"
import { getJobPositionCatalogRed } from "../../../business/Reducers/catalogs/jobPosition/getJobPositionCatalogRed"
import { getCardMappingCatalogRed } from "../../../business/Reducers/catalogs/cardMapping/getJobPositionCatalogRed"

export const useGetSamCounter = () => {
  const [state, dispatch] = useReducer(getSamCounterCatalog, globalInitialState);
  return { state, dispatch };
}

export const usePatchSamCounter = () => {
  const [state, dispatch] = useReducer(patchSamCounterCatalog, globalInitialState);
  return { state, dispatch };
}

export const useGetInvetoryToExcelState = () => {
  const [state, dispatch] = useReducer(getInventory_Red, globalInitialState);
  return { state, dispatch };
}

export const useGetCatalogProjects = () => {
  const [state, dispatch] = useReducer(getProjectsCatalogRed, globalInitialState);
  return { state, dispatch };
}

export const useGetCatalogDevices = () => {
  const [state, dispatch] = useReducer(getDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch };
}

export const useGetCatalogOperators = () => {
  const [state, dispatch] = useReducer(getOperatorsCatalogRed, globalInitialState);
  return { state, dispatch };
}

export const useGetLocationCatalogState = () => {
  const [state, dispatch] = useReducer(getLocationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostLocationCatalogState = () => {
  const [state, dispatch] = useReducer(postLocationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutLocationCatalogState = () => {
  const [state, dispatch] = useReducer(putLocationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteLocationCatalogState = () => {
  const [state, dispatch] = useReducer(deleteLocationsCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetTransportCompaniesCatalogState = () => {
  const [state, dispatch] = useReducer(getTransportCompaniesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostTransportCompaniesCatalogState = () => {
  const [state, dispatch] = useReducer(postTransportCompaniesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutTransportCompaniesCatalogState = () => {
  const [state, dispatch] = useReducer(putTransportCompaniesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteTransportCompaniesCatalogState = () => {
  const [state, dispatch] = useReducer(deleteTransportCompaniesCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetStationsCatalogState = () => {
  const [state, dispatch] = useReducer(getStationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostStationsCatalogState = () => {
  const [state, dispatch] = useReducer(postStationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutStationsCatalogState = () => {
  const [state, dispatch] = useReducer(putStationsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteStationsCatalogState = () => {
  const [state, dispatch] = useReducer(deleteStationsCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetEconomicNumbersCatalogState = () => {
  const [state, dispatch] = useReducer(getEconomicNumbersCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostEconomicNumbersCatalogState = () => {
  const [state, dispatch] = useReducer(postEconomicNumbersCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutEconomicNumbersCatalogState = () => {
  const [state, dispatch] = useReducer(putEconomicNumbersCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteEconomicNumbersCatalogState = () => {
  const [state, dispatch] = useReducer(deleteEconomicNumbersCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetOperatorsCatalogState = () => {
  const [state, dispatch] = useReducer(getOperatorsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostOperatorsCatalogState = () => {
  const [state, dispatch] = useReducer(postOperatorsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutOperatorsCatalogState = () => {
  const [state, dispatch] = useReducer(putOperatorsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteOperatorsCatalogState = () => {
  const [state, dispatch] = useReducer(deleteOperatorsCatalogRed, globalInitialState);
  return { state, dispatch }
}

//job position 
export const useGetJobPositionCatalogState = () => {
  const [state, dispatch] = useReducer(getJobPositionCatalogRed, globalInitialState);
  return { state, dispatch }
}


//job position 
export const useGetCardMappingCatalogState = () => {
  const [state, dispatch] = useReducer(getCardMappingCatalogRed, globalInitialState);
  return { state, dispatch }
}

//
export const useGetPointsOfSalesCatalogState = () => {
  const [state, dispatch] = useReducer(getPointsOfSalesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostPosCatalogState = () => {
  const [state, dispatch] = useReducer(postPosCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutPosCatalogState = () => {
  const [state, dispatch] = useReducer(putPosCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeletePosCatalogState = () => {
  const [state, dispatch] = useReducer(deletePosCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetProjectsCatalogState = () => {
  const [state, dispatch] = useReducer(getProjectsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostProjectsCatalogState = () => {
  const [state, dispatch] = useReducer(postProjectsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutProjectsCatalogState = () => {
  const [state, dispatch] = useReducer(putProjectsCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteProjectsCatalogState = () => {
  const [state, dispatch] = useReducer(deleteProjectsCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetRoutesCatalogState = () => {
  const [state, dispatch] = useReducer(getRoutesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostRoutesCatalogState = () => {
  const [state, dispatch] = useReducer(postRoutesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutRoutesCatalogState = () => {
  const [state, dispatch] = useReducer(putRoutesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteRoutesCatalogState = () => {
  const [state, dispatch] = useReducer(deleteRoutesCatalogRed, globalInitialState);
  return { state, dispatch }
}
//
export const useGetDeviceTypesCatalogState = () => {
  const [state, dispatch] = useReducer(getDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostDeviceTypesCatalogState = () => {
  const [state, dispatch] = useReducer(postDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutDeviceTypesCatalogState = () => {
  const [state, dispatch] = useReducer(putDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteDeviceTypesCatalogState = () => {
  const [state, dispatch] = useReducer(deleteDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostExportDeviceTypesCatalogState = () => {
  const [state, dispatch] = useReducer(postExportDeviceTypesCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useGetSamKeyVersions = () => {
  const [state, dispatch] = useReducer(getSamKeyVersions, globalInitialState);
  return { state, dispatch }
}

export const useGetProfileCardCatalogState = () => {
  const [state, dispatch] = useReducer(getProfileCardCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePostProfileCardCatalogState = () => {
  const [state, dispatch] = useReducer(postProfileCardCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const useDeleteProfileCardCatalogState = () => {
  const [state, dispatch] = useReducer(deleteProfileCardCatalogRed, globalInitialState);
  return { state, dispatch }
}

export const usePutProfileCardCatalogState = () => {
  const [state, dispatch] = useReducer(putProfileCardCatalogRed, globalInitialState);
  return { state, dispatch }
}