import { IServiceMenuComponent } from '@/interfaces/interfaceCatalogs';
import { classNames } from 'primereact/utils';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { InputNumberComponent } from '../../../../components/InputNumberComponent';

export const ProfileCardForm = (props: IServiceMenuComponent) => {
  const { serviceCatalog } = props;

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={serviceCatalog.formikProfileCard.handleSubmit}>

        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'text'}
            name={'name'}
            onChange={serviceCatalog.formikProfileCard.handleChange}
            value={serviceCatalog.formikProfileCard?.values?.['name'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid(
                'name',
                serviceCatalog.formikProfileCard.errors,
                serviceCatalog.formikProfileCard.touched
              ),
            })}
          />
          {serviceCatalog.getFormErrorMessage(
            'name',
            serviceCatalog.formikProfileCard.errors,
            serviceCatalog.formikProfileCard.touched
          )}
        </div>

        <div className="flex flex-col mb-6">
          <InputNumberComponent
            label={'Número'}
            name={'number'}
            useGrouping={false}
            onChange={serviceCatalog.formikProfileCard.handleChange}
            value={
              serviceCatalog.formikProfileCard?.values?.['number']
            }
            placeholder={'Número'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid(
                'number',
                serviceCatalog.formikProfileCard.errors,
                serviceCatalog.formikProfileCard.touched
              ),
            })}
          />
          {serviceCatalog.getFormErrorMessage(
            'number',
            serviceCatalog.formikProfileCard.errors,
            serviceCatalog.formikProfileCard.touched
          )}
        </div>

        <ButtonComponent
          label={serviceCatalog.buttonLabel}
          type="submit"
        />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};
