import { Column } from 'primereact/column';

import { TableComponent } from '../../../components/Table';
import { Filters } from './Filters';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';

interface IInventaryTableProps {
  serviceInvetory: IInventoryProvider;
}


export const InventaryTable = (props: IInventaryTableProps) => {
  const {
    serviceInvetory,
  } = props;

  const getColumnsToDisplayIntable = () => {
    let columns = serviceInvetory.dinamicColumns.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          filterField={column.field} 
          dataType={column.isFilter ? column.dataType : undefined}
          header={column.header}
          bodyClassName={'!text-black'}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
            minWidth:  "16rem" 
          }}
          headerClassName="  !bg-tableHeader !text-withe!justify-between "
          sortable={column.sortable}
          filterMenuStyle={{ minWidth: '18rem' }}
          filter={column.filter}
          body={column.body}
          showFilterMenu={false}
          filterPlaceholder={`Buscar por ${column.header}`}
        />
      );
    });

    columns.unshift(
      <Column
        selectionMode="multiple"
        headerStyle={{ width: '3rem' }}
        key={0}></Column>
    );

    return columns;
  };

  return (
    <div className='flex flex-col relative w-full h-full'>
      <Filters
        serviceInvetory={serviceInvetory}
      />
      <div className="h-full flex w-full overflow-hidden relative">
        <TableComponent
          value={serviceInvetory.samListDataTemp}
          sortField="name"
          responsiveLayout="scroll"
          className='flex h-full w-full m-1 relative'
          globalFilterFields={[]}
          resizableColumns
          setSort={serviceInvetory.setSort}
          sort={serviceInvetory.sort}
          reorderableColumns={true}
          OnEmptyMessage="No se encontraron registros, seleccione algún filtro y presione el botón 'Enviar' para mostrar resultados"
          selectionMode={'checkbox'}
          selection={serviceInvetory.selectedSam}
          loading={serviceInvetory.loader}
          filters={serviceInvetory.filters}
          filterDisplay="row"
          paginator={true}
          rowsPerPageOptions={true}
          onSelectionChange={(e: any) => serviceInvetory.setSelectedSam(e.value)}>
          {serviceInvetory.samListDataTemp.length > 0 ? getColumnsToDisplayIntable() : null}
        </TableComponent>
      </div>
    </div>
  );
};
